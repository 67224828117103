import { PageNotFound } from "common/page-not-found";
import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import APP_ROUTES from "routes";
import LazyLoader from "./components/common/LazyLoader";
// import { PrivateRoute } from "./components/common/PrivateRoute";
import ProtectedRoute from "components/common/protected-route";
//import DashBoard from "./components/dashboard/DashBoard";
//import Admin from "components/Admin/Admin";
//import InternalAdmin from "components/admin-internal/InternalAdmin";
//import LogoutPage from "components/login/LogoutPage";

const Login = lazy(() => import("./components/login/LoginPage"));
const LogoutPage = lazy(() => import("./components/login/LogoutPage"));
const Admin = lazy(() => import("./components/Admin/Admin"));
const InternalAdmin = lazy(() =>
  import("./components/admin-internal/InternalAdmin")
);
const DashBoard = lazy(() => import("./components/dashboard/DashBoard"));

function App() {
  return (
    <Suspense fallback={<LazyLoader />}>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route element={<ProtectedRoute />}>
          <Route path="/" element={<DashBoard />} />
          {APP_ROUTES.map(({ path, element: Element }, i) => (
            <Route exact key={i} path={path} element={<Element />} />
          ))}
          <Route exact path="/admin" element={<Admin />} />
          <Route exact path="/admin-internal" element={<InternalAdmin />} />
          <Route exact path="/logout" element={<LogoutPage />} />
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Suspense>
  );
}

export default App;
